import React, { useEffect, useContext, useState, useRef } from "react"
import Loader from 'react-loader-spinner'
import swal from 'sweetalert2'
import { Router } from '@reach/router'
import EventPage from '../templates/event'
import EventFeed from '../components/EventFeed'

import Helmet from "react-helmet"
// import FullCalendar from '@fullcalendar/react'


import Header from '../components/Header'


import NewsletterImage from '../assets/images/general/newsletter.jpg'


import { getStaticPageData, getEvents, getNewsletter, subscribeToMailchimp } from "../lib/API"
import { useLocalData, useData } from "../lib/hooks"
import BannerContext from "../lib/context"
import BannerSection from "../components/BannerSection"

import loadable from '@loadable/component'
import SmallSection, { TextSection } from "../components/Section"
import Modal from "../components/Modal"
import { changeFormValue } from "../lib/utils"



// const Calendar = loadable( () => import( '../components/Calendar' ) )
const getRepeat = ( date ) =>  [ (new Date(date)).getDay() ]

const daysInMonth = ( date ) => new Date(date.getFullYear(), date.getMonth(), 0).getDate()





const Events = () => {
    
    const [ events, setEvents ] = useState( [] )
    const [ subscribeModal , setSubscribeModal ] = useState( false )
    const [ loading, setLoading ] = useState( false )
    const [ subscribeEmail, setSubscribeEmail ] = useState( '' )
    const [ subscribeFirstName, setSubscribeFirstName ] = useState( '' )
    const [ subscribeLastName, setSubscribeLastName ] = useState( '' )


    const subscribeModalRef = useRef()

    const newsletter = useData( getNewsletter )

    const subscribe = async e => {
        e.preventDefault()
        e.stopPropagation()
        
        setLoading( true )
        const data = { email: subscribeEmail, firstName: subscribeFirstName, lastName: subscribeLastName }
        const res = await subscribeToMailchimp( data )
        setLoading( false )
        
        if( res && res.id  ) {
            if( res.status === 'pending' )
                swal.fire( 'Success', 'Verification email has been sent', 'success' ).then( () => {
                    setSubscribeEmail( '' )
                    setSubscribeFirstName( '' )
                    setSubscribeLastName( '' )
                    setSubscribeModal( false )
                } )
            else if( res.status === 'subscribed' )
                swal.fire( 'Error', 'Already subscribed', 'warning' ).then( () => {
                    setSubscribeEmail( '' )
                    setSubscribeFirstName( '' )
                    setSubscribeLastName( '' )
                    setSubscribeModal( false )
                } )
        }
        else{
            swal.fire( 'Could not subscribe', 'Something went wrong somewhere', 'error' )
        }
    }

    useEffect( () => {
        const getAllEvents = async () => {
            const res = await getEvents()
            // const eventData = res.map( event => ( {
            //     ...event,
            //     start: new Date( event.start ),
            //     end: new Date( event.end ),
            //     startTime: event.repeated && (new Date( event.start )).toLocaleTimeString('en-GB'),
            //     endTime: event.repeated && (new Date( event.end )).toLocaleTimeString('en-GB'),
            //     daysOfWeek: event.repeated && getRepeat( event.start ),
            //     allDay: event.all_day
            //   } ))
            
            const eventData = res.reduce( ( allEvents, event ) => {
                const eventObj = {
                    ...event,
                    start: new Date( event.start ),
                    end: new Date( event.end ),
                    originalStart: new Date( event.start ),
                    originalEnd: new Date( event.end ),
                    startTime: event.repeated && new Date( event.start ).toLocaleTimeString('en-GB'),
                    endTime: event.repeated && new Date( event.end ).toLocaleTimeString('en-GB'),
                    daysOfWeek: event.repeated && getRepeat( event.start ),
                    allDay: event.all_day
                }
                if( event.repeated ) {
                    const futureEvents = [1,2,3,4].reduce( (arr, i) => {
                        const d = new Date();
                        const futureDate = d.getDate() + (7 * i)
                        if( futureDate <= daysInMonth(d)) {
                            const dayOfWeek = eventObj.start.getDay()
                            d.setDate(futureDate + (dayOfWeek + 7 - d.getDay()) % 7); 
                            d.setHours(eventObj.start.getHours())
                            d.setMinutes(eventObj.start.getMinutes())
                            d.setFullYear(d.getFullYear())

                            const e = new Date(d.toDateString())
                            e.setDate(futureDate + (dayOfWeek + 7 - e.getDay()) % 7); 
                            e.setHours(eventObj.end.getHours())
                            e.setMinutes(eventObj.end.getMinutes())
                            e.setFullYear(e.getFullYear())
                            arr.push({
                                ...event,
                                start: d,
                                end: e,
                                originalStart: d,
                                originalEnd: e,
                                startTime: event.repeated && d.toLocaleTimeString('en-GB'),
                                endTime: event.repeated && e.toLocaleTimeString('en-GB'),
                                daysOfWeek: event.repeated && getRepeat( event.start ),
                                allDay: event.all_day
                            })
                        }
                        return arr
                    }, [])
                    allEvents = allEvents.concat(futureEvents)
                }

                if( !event.additional_times ){
                    allEvents.push(eventObj)
                } else {
                    allEvents = allEvents.concat([ 
                    eventObj, 
                    ...event.additional_times.map( times => ( {
                        ...event,
                        start: new Date( times.start_date ),
                        end: new Date( times.end_date ),
                        originalStart: new Date( event.start ),
                        originalEnd: new Date( event.end ),
                        startTime: event.repeated && new Date( times.start_date ).toLocaleTimeString('en-GB'),
                        endTime: event.repeated && new Date( times.end_date ).toLocaleTimeString('en-GB'),
                        daysOfWeek: event.repeated && getRepeat( times.start_date ),
                        allDay: event.all_day
                    } ) )
                    ])
                }
                return allEvents
            }, [] )
            console.log(eventData)
            setEvents( eventData )
        }

        getAllEvents()
    }, [])

    const siteTitle = "Blackheath and Charlton Baptist Church"
    
    // const headerImages = useData( getEventsHeader )
    const pageName = 'whats-on-banners'
    // const pageData = useLocalData( getStaticPageData, pageName, pageName )
    const pageData = useContext( BannerContext )[pageName]

    const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = header ? header.images : []
    const headerTitle = header ? header.title : "What's On"
    // const headerIcon = <span className="icon major"><i className="fas fa-calendar-alt"></i></span>
    const headerButtons = [
        { url: "/events#one", text: "View Events" }
    ]
    
    return (
        <>
            <div id='whats-on-page'>
            
                <Helmet title={`${siteTitle} - What's On`} />
                <Header images={headerImages} headerTitle={headerTitle} tint={0.3} headerButtons={headerButtons} />
                <EventFeed events={events} />
                {/* <Calendar events={events}/> */}

                <TextSection
                    id='newsletter'
                    title='Newsletter'
                    style='large'
                    description='Keep up with the latest at Blackheath and Charlton Baptist Church with our weekly newsletter'
                    buttons={[
                        { external: true, url: newsletter.file, text: 'Read Newsletter', style: 'transparent-button'},
                        { text: 'Subscribe', style: 'transparent-button', onClick: () => setSubscribeModal( true ) }
                    ]}
                    background={[ NewsletterImage ]}
                    tint={0.45}
                    blur={0.4}

                />

                <Modal ref={subscribeModalRef} className="subscribe" show={subscribeModal} handleClose={() => setSubscribeModal( false )}>
                    <h2 className="major">Stay up to date with our Church Newsletter</h2>
                    <form className="login-form" method="POST" onSubmit={subscribe}>
                        <input className="form-input" type="text" name="fname" value={subscribeFirstName} onChange={e => changeFormValue( e, setSubscribeFirstName )} placeholder="First Name*" required /><br/>
                        <input className="form-input" type="text" name="lname" value={subscribeLastName} onChange={e => changeFormValue( e, setSubscribeLastName )} placeholder="Last Name*" required /><br/>
                        <input className="form-input" type="email" name="email" value={subscribeEmail} onChange={e => changeFormValue( e, setSubscribeEmail )} placeholder="Email*" required /><br/>
                        <br />
                        {!loading ? <input type="submit" value="Submit" className="button special" /> :
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={40}
                            width={50}
                        />}
                    </form>
                </Modal>
                <BannerSection banners={banners} />

            </div>
        </>
    )
}



const Page = () =>  <Router> 
                        <EventPage path="/whats-on/:id" exact />
                        <Events path="/whats-on" exact />
                    </Router>

export default Page